import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head-traning'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm, datas, courses) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        const pdfContent = [{
            columns: reportHeadData.reportHeadColumn
          },
          { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
          { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
          { text: reportHeadData.address, style: 'address', alignment: 'center' },
          { text: reportTitle, style: 'hh', alignment: 'center' }
        ]
        const bodySeperate = [
          [
            { text: vm.$t('elearning_iabm.circular_memo_no'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: data.circular_memo_no, style: 'td', alignment: 'left' },
            { text: vm.$t('elearning_iabm.batch_no'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.getColumnName(vm.allBatchList, data.batch_no), style: 'td', alignment: 'left' }
          ]
        ]
        bodySeperate.push(
          [
            { text: vm.$t('elearning_config.fiscal_year'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? data.fiscal_year_bn : data.fiscal_year, style: 'td', alignment: 'left' },
            {},
            {},
            {}
          ]
        )
        if (Store.state.Auth.activeRoleId === 1 || Store.state.Auth.authUser.org_id === 1 || Store.state.Auth.authUser.org_id === 12) {
          bodySeperate.push([
            { text: vm.$t('globalTrans.organization'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? data.org_bn : data.org, style: 'td', alignment: 'left' },
            {},
            {},
            {}
          ]
          )
        }
        bodySeperate.push(
          [
            { text: vm.$t('elearning_config.training_type'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? data.training_type_bn : data.training_type, style: 'td', alignment: 'left' },
            { text: vm.$t('elearning_config.training_category'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? data.training_category_bn : data.training_category, style: 'td', alignment: 'left' }
          ]
        )
        bodySeperate.push(
          [
            { text: vm.$t('elearning_config.training_title'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? data.training_title_bn : data.training_title, style: 'td', alignment: 'left' },
            {},
            {},
            {}
          ]
        )
        pdfContent.push(
          {
            table: {
              style: 'tableData',
              headerRows: 0,
              widths: ['18%', '3%', '29%', '18%', '3%', '29%'],
              body: bodySeperate
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            }
          }
        )
        pdfContent.push({ text: vm.$t('elearning_tim.course_evaluation') + ' ' + vm.$t('globalTrans.list'), style: 'hh', alignment: 'center' })
        // option
      const allRows = []
      const firstData = []
      firstData.push(
        { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
        { text: vm.$t('globalTrans.name'), style: 'th', alignment: 'center' },
        { text: vm.$t('globalTrans.mobile'), style: 'th', alignment: 'center' },
        { text: vm.$t('elearning_tim.posting_office'), style: 'th', alignment: 'left' },
        { text: vm.$t('elearning_tim.obtained_marks'), style: 'th', alignment: 'right' }
      )
      allRows.push(firstData)
      datas.details.filter((course, optionIndex) => {
        let dataDes = ''
        let officeData = ''
        if (course.training_status === 1) {
          dataDes = vm.$i18n.locale === 'bn' ? course.designation_name_bn : course.designation_name
        } else {
          dataDes = course.designation
        }
        officeData = vm.$i18n.locale === 'bn' ? course.office_name_bn : course.office_name + ', ' + vm.$i18n.locale === 'bn' ? course.org_name_bn : course.org_name
        const rowItem = [
          { text: vm.$n(optionIndex + 1), style: 'td', alignment: 'center' },
          { text: vm.$i18n.locale === 'bn' ? course.name_bn : course.name, style: 'td', alignment: 'center' },
          { text: ((vm.$i18n.locale === 'bn' ? '০' : '0') + vm.$n(course.mobile, { useGrouping: false })), style: 'td', alignment: 'center' },
          { text: dataDes + officeData, style: 'td', alignment: 'left' },
          { text: vm.$n(course.obtained_mark, { useGrouping: false }), style: 'td', alignment: 'right' }
        ]
        allRows.push(rowItem)
      })
      pdfContent.push({ text: '', style: 'tableMargin' })
      pdfContent.push({
        table: {
          headerRows: 2,
          widths: ['10%', '20%', '20%', '25%', '25%'],
          body: allRows
        }
      })
      var docDefinition = {
      content: pdfContent,
      pageSize: 'A4',
      pageOrientation: 'portrait',
      // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
      styles: {
        th: {
          fontSize: (i18n === 'bn') ? 11 : 9,
          margin: [3, 3, 3, 3]
        },
        td: {
          fontSize: (i18n === 'bn') ? 11 : 9,
          margin: [3, 3, 3, 3]
        },
        header: {
          fontSize: 16,
          bold: true,
          margin: [0, 0, 0, 0]
      },
      org: {
          fontSize: 13,
          bold: true,
          margin: [0, -25, 10, 5]
      },
      address: {
          fontSize: 11,
          margin: [0, 0, 0, 5]
      },
      hh: {
          fontSize: 13,
          bold: true,
          margin: [10, 10, 25, 20]
      },
        header2: {
          fontSize: 14,
          margin: [0, 10, 0, 20]
        },
        fertilizer: {
          fontSize: 10,
          margin: [10, 10, 0, 20]
        },
        headerPort1: {
          fontSize: 10,
          margin: [0, 20, 0, 0]
        },
        headerPort: {
          fontSize: 10,
          margin: [0, 4, 0, 15]
        },
        krishi: {
          margin: [0, -5, 0, 15],
          alignment: 'center'
        },
        header3: {
          fontSize: 9,
          margin: [0, 0, 0, 4]
        },
        tableSubHead: {
          margin: [0, 5, 0, 15]
        }
      }
    }
    pdfMake.createPdf(docDefinition, null, null, null).download('post-course-evaluation')
  } catch (error) {
    if (error) {}
  }
  Store.commit('mutateCommonProperties', {
    loading: false
  })
}
export default {
  exportPdfDetails
}
